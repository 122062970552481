$displays: block, inline, inline-block, none, flex, inline-flex, grid, inline-grid, table, table-row, table-cell, list-item;
$align-items: (
    stretch: stretch,
    center: center,
    start: flex-start,
    end: flex-end,
    base: baseline,
    initial: initial,
    inherit: inherit,
);
$justify-content: (
    start: flex-start,
    end: flex-end,
    center: center,
    between: space-between,
    around: space-around,
    evenly: space-evenly,
    initial: initial,
    inherit: inherit
);

$flex-directions: (
    row: row,
    rr: row-reverse,
    col: column,
    cr:column-reverse
);

$position-values: (
    static,
    relative,
    absolute,
    fixed,
    sticky
);

@mixin display-classes {
    @for $i from 1 through 1000 {
        .flex#{$i} {
            flex: $i;
        }
    }

    @each $value in $displays {
        .#{$value} {
            display: $value;
        }
    }

    @each $name, $value in $align-items {
        .align-#{$name} {
            align-items: $value;
        }
    }

    @each $name, $value in $justify-content {
        .justify-#{$name} {
            justify-content: $value;
        }
    }

    @each $name, $value in $flex-directions {
        .flex-#{$name} {
            flex-direction: $value;
        }
    }

    @each $name in $position-values {
        .pos-#{$name} {
            position: $name;
        }
    }
}

@include display-classes;