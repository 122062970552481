input {
    width: 100%;
    padding: 12px;
    outline: none;
    min-height: 40px;
    border-radius: 4px;
    background: white;
    border: .5px solid rgba(0, 0, 0, 0.1);
    font-weight: normal;
    transition: ease 250ms;
    &:focus {
        border: .5px solid rgba(0, 0, 255, 0.5);
    }
}