@import './css/input';
@import './css/display';
@import './css/spacing';
@import './css/button';
@import './css/constant';
@import './css/loader';

html,
body,
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 13px;
  text-decoration: none;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

/* For WebKit-based browsers (Chrome, Safari, Opera, Firefox, Internet Explorer and Edge) */
::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

/* text elipsis... */
.elipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

// app
.app {
    width: 100%;
    height: 100vh;
    display: flex;
    overflow:hidden;
    background: $bg;
    padding: 16px;
    gap: 16px;
    .side__menu {
        width: 256px;
        height: calc(100vh - 32px);
        background: white;
        position: sticky;
        border-radius: $radius;
        padding: calc($radius / 2);
        display: flex;
        flex-direction: column;
        
        .side__menu__list {
            flex: 1;
            display: flex;
            flex-direction: column;
            a {
                    height: 40px;
                    padding: 8px 16px;
                    color: grey;
                    border-radius: calc($radius / 2);
                    display: flex;
                    align-items: center;
            
                    &.active {
                        background: blue;
                        color: white;
                    }
                }
        }
    }
    section {
        width: 100%;
        flex: 1;
        padding: $radius;
        border-radius: $radius;
        background: white;
        overflow: scroll;
    }
}

// login
.login {
    width: 100%;
    height: 100vh;

    .login__container {
        width: 100%;
        margin: 0 auto;
        max-width: 500px;
        border-radius: 12px;
        background: white;
        box-shadow: 0 5px 15px 0 rgb(156 39 176 / 10%);
    }
}

// posts list
.posts__lists {
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));

    .post__card {
        background: white;
        box-shadow: 4px 12px 10px 0px rgba(0, 0, 0, 0.031);

        h2 {
            font-size: 16px;
            text-transform: capitalize;
            @extend .elipsis;
            -webkit-line-clamp: 1;
        }

        p {
            font-size: 12px;
            @extend .elipsis;
            -webkit-line-clamp: 3;
            color: rgba(0, 0, 0, 0.75);
        }
    }
}

// photos
.photos__list {
    grid-template-columns: repeat(auto-fit, minmax(8%, 1fr));

    div {
        width: 100%;
        height: 100px;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition: all 0.3s ease;

            &:hover {
                transform: scale(1.5);
            }
        }
    }
}

// users
.users__list {
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    .user__card {
        background: white;
        box-shadow: 0 5px 15px 0 rgb(156 39 176 / 10%);
    }
}
