@use "sass:list";
@use "sass:math";

// margin, padding, gap, border-radius
@mixin space-classes {
    @for $i from 0 through 24 {
        $space: $i * 4px;

        .m#{$i} {
            margin: $space;
        }

        .ml#{$i} {
            margin-left: $space;
        }

        .mr#{$i} {
            margin-right: $space;
        }

        .mt#{$i} {
            margin-top: $space;
        }

        .mb#{$i} {
            margin-bottom: $space;
        }

        .mx#{$i} {
            margin-left: $space;
            margin-right: $space;
        }

        .my#{$i} {
            margin-top: $space;
            margin-bottom: $space;
        }

        .p#{$i} {
            padding: $space;
        }

        .pl#{$i} {
            padding-left: $space;
        }

        .pr#{$i} {
            padding-right: $space;
        }

        .pt#{$i} {
            padding-top: $space;
        }

        .pb#{$i} {
            padding-bottom: $space;
        }

        .px#{$i} {
            padding-left: $space;
            padding-right: $space;
        }

        .py#{$i} {
            padding-top: $space;
            padding-bottom: $space;
        }

        .gap#{$i} {
            gap: $space;
        }

        .radius#{$i} {
            border-radius: $space
        }
    }
}

@include space-classes;

// opacity
@mixin opacity-classes {
    @for $i from 0 through 10 {
        $opacity: math.div($i, 10);

        .opacity#{$i} {
            opacity: $opacity;
        }
    }
}

@include opacity-classes;